import axios from "axios";
import { CreateClientRequestParams } from "../types";
const apiUrl = process.env.REACT_APP_API_URL;
// Create Client
export const createClient = async (data: CreateClientRequestParams) => {
  try {
    const response = await axios.post(
      `${apiUrl}/ai_master/create-client`,
      data
    );
    return response.data;
  } catch (error: any) {
    return false;
  }
};
// Get Client Details
export const getClientDetails = async (client_id: string) => {
  try {
    const response = await axios.post(
      `${apiUrl}/ai_master/get-client-details`,
      {
        client_id: client_id
      }
    );
    return response.data;
  } catch (error: any) {
    return false;
  }
};
// Predefined Templates
export const getPredefinedTemplates = async (client_id: string) => {
  try {
    const response = await axios.post(
      `${apiUrl}/ai_master/get-predefined-templates`,
      {
        client_id: client_id
      }
    );
    return response.data;
  } catch (error: any) {
    return false;
  }
};
// Get Final Result From User Prompt
export const getFinalResult = async (
  client_id: string,
  user_query: string,
  threadID: string
) => {
  try {
    const response = await axios.post(`${apiUrl}/ai_master/get-result`, {
      client_id: client_id,
      user_prompt: user_query,
      thread_id: threadID
    });
    return response.data;
  } catch (error: any) {
    return false;
  }
};
// Get Client History
export const getClientHistory = async (client_id: string) => {
  try {
    const response = await axios.post(
      `${apiUrl}/ai_master/get-client-history`,
      {
        client_id: client_id
      }
    );
    return response.data;
  } catch (error: any) {
    return false;
  }
};
