import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  Paper,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Modal,
  Grid,
  Divider,
  FormControlLabel,
  Switch,
  TextField,
  InputAdornment,
  IconButton,
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItemText
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import {
  getPredefinedTemplates,
  getFinalResult,
  getClientHistory,
  createClient,
  getClientDetails
} from "../api/langchain";
import { toast } from "react-toastify"; // Import toast
import "../styles/datainput.css";
// Charts
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  LineChart,
  Line,
  Legend
} from "recharts";
import { v4 as uuidv4 } from "uuid";
import {
  clientDetails,
  clientDetailsAPIResponse,
  CreateClientRequestParams,
  predefinedPromptTemplatesAPIResponse
} from "../types";
// Icon
import SettingsIcon from "@mui/icons-material/Settings";
import styled from "@emotion/styled";
import { connectDatabase } from "../api/database";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";

// Dependencies
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  border: "none !important",
  outline: "none !important",
  borderRadius: "20px"
};
// Switch Color
const GreenSwitch = styled(Switch)({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#81DDA0",
    "& + .MuiSwitch-track": {
      backgroundColor: "#81DDA0"
    }
  }
});
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const DataInput: React.FC = () => {
  const [clientID, setClientID] = useState<string>(
    "43c7d3df-b7ee-4031-a5da-d15eb9ab3ea1753"
  );

  const PRODUCTION_IP = process.env.REACT_APP_PRODUCTION_IP;

  console.log(PRODUCTION_IP, "PRODUCTION_IP");

  // States
  const [clientDetailsInfo, setClientDetailsInfo] = useState<any>(null);
  const [databaseChecking, setDatabaseChecking] = useState(false);
  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [threadLoading, setThreadLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [templates, setTemplates] = useState<any>([]);
  // Show Result
  const [fetchingData, setFetchingData] = useState<boolean>(false);
  // Threads History
  const [threads, setThreadsData] = useState<any>([]);
  const [chatView, setChatView] = useState<boolean>(false);
  const [chatData, setChatData] = useState<any>([]);
  const [threadID, setThreadID] = useState("");
  const [isNewThread, setIsNewThread] = useState<Boolean>(false);
  // Active Options
  const [activeMenu, setActiveMenu] = useState<Number>(1);
  const [activeDBType, setActiveDBType] = useState<Number>(2);
  // Database Configuration
  const [databaseConfiguration, setDatabaseConfiguration] =
    useState<CreateClientRequestParams>({
      db_type: 2,
      db_host: "",
      db_database: "",
      db_user: "",
      db_password: "",
      client_id: clientID ? clientID : "",
      direct_connection: false
    });
  // Password
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Get Predefined Templates
  const getPredefinedTemplatesFunction = async () => {
    setTemplatesLoading(true);
    if (clientID) {
      const result: predefinedPromptTemplatesAPIResponse =
        await getPredefinedTemplates(clientID);
      // const result = {
      //   data: [
      //     "Retrieve all completed vehicle trips with their total distance traveled during this year, useful for annual performance evaluations or maintenance planning. Use the 'vehicle_trip' collection and filter based on the 'start_date' and 'end_date' fields.",
      //     "Link vehicle information with its respective owner details. Useful for customer service to quickly address owner queries or service needs. This requires joining the 'vehicles' collection with the 'users' collection on 'user_id'.",
      //     "Generate a list of all active users who logged into the system within the last month, along with their login history. This helps in analyzing user engagement over recent weeks. Query the 'login_history' collection and use the 'created_at' field to filter records from the last 30 days.",
      //     "Get a summary of newly registered devices and their installation status over the current year, useful for inventory management and service planning. Query the 'logger_device_inventory' collection and filter using 'created_at' field.",
      //     "Find all error logs related to a specific application category during the previous week to identify and resolve recent critical issues. Use the 'audit_error_logs' collection and filter based on the 'created_at' field and the 'category' field.",
      //     "List all vehicle models launched in the current month to track new model release and market trends. Use the 'vehicle_models' collection and filter based on the 'launch_date' field.",
      //     "Gather firmware update information for all devices that have been updated within the last day to verify the updates' deployment success across the network. Query the 'firmware_ota_update_history' collection and use the 'updated_at' field for filtering.",
      //     "Display a combined record of fuel replacements and corresponding CO2 savings over the past week, providing insights into environmental impact and cost savings. This will involve a query to 'fuel_replaced_summary' and 'co2_saved_summary' collections and join them based on 'UID' with filters on the 'created_at' field."
      //   ]
      // };
      if (result) {
        setTemplates(result.data);
      } else {
        toast.error("Error while fetching predefined templates");
      }
      setTemplatesLoading(false);
    }
  };
  // Get Result From Prompt
  const getResultsFromPrompt = async () => {
    setChatView(true);
    setFetchingData(true);
    if (prompt && clientID) {
      let threadIDInfo = threadID;
      let isNewThreadInfo = isNewThread;
      if (!threadIDInfo) {
        threadIDInfo = uuidv4();
        setIsNewThread(true);
        setThreadID(threadIDInfo);
        setChatData([]);
        setChatView(true);
        isNewThreadInfo = true;
      }
      const result: any = await getFinalResult(clientID, prompt, threadIDInfo);
      if (result && result.data) {
        let currentChatData = chatData;
        currentChatData.push(result.data);
        setChatData(currentChatData);
        setFetchingData(false);
        setPrompt("");
        if (isNewThreadInfo) {
          setIsNewThread(false);
          getClientHistoryFunc();
        }
        setTimeout(() => {
          if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
          }
        }, 10);
      } else {
        setFetchingData(false);
        toast.error(
          result?.message ? result?.message : "Error while generating data"
        );
      }
    }
  };
  console.log(chatData, "chatData");

  // Get Client History
  const getClientHistoryFunc = async () => {
    setThreadsData([]);
    setThreadLoading(true);
    if (clientID) {
      const result = await getClientHistory(clientID);
      if (result) {
        let threadArray = result?.data ? result?.data : [];
        threadArray = Object.fromEntries(Object.entries(threadArray).reverse());
        setThreadsData(threadArray);
      }
      setThreadLoading(false);
    }
  };
  // On Render Fetch Predefined Templates And Thread History
  useEffect(() => {
    let clientIDInfo = localStorage.getItem("client_id")
      ? localStorage.getItem("client_id")
      : "43c7d3df-b7ee-4031-a5da-d15eb9ab3ea1753";
    if (clientIDInfo) {
      setClientID(clientIDInfo);
      setDatabaseConfiguration({
        ...databaseConfiguration,
        client_id: clientIDInfo
      });
      clientDetailsFunc(clientIDInfo);
    }
  }, []);
  // Get Thread Data
  const getChatData = (threadID: string) => {
    setThreadID(threadID);
    let chatDataArray = threads[threadID];
    setChatData(chatDataArray);
    setChatView(true);
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };
  // Random Colors
  const getRandomColor = (index: number) => {
    const colors = [
      "#8884d8",
      "#82ca9d",
      "#ff7300",
      "#d45087",
      "#003f5c",
      "#ffa600",
      "#0088fe"
    ];
    return colors[index % colors.length];
  };
  // Bar Chart Random Color
  const getRandomColorBarChart = () => {
    // const letters = "0123456789ABCDEF";
    // let color = "#";
    // for (let i = 0; i < 6; i++) {
    //   color += letters[Math.floor(Math.random() * 16)];
    // }
    return "#d45087";
  };
  // Utils
  // Function to get the first key with a string value
  const getFirstStringKey = (obj: any) => {
    for (const key in obj) {
      if (typeof obj[key] === "string") {
        return key; // Return the first matching key
      }
    }
    return "name"; // Return null if no string keys found
  };
  // Function to get the first key with a number value
  const getFirstNumberKey = (obj: any) => {
    for (const key in obj) {
      if (typeof obj[key] === "number") {
        return key; // Return the first matching key
      }
    }
    return "count"; // Return null if no number keys found
  };
  const getFirstDateKey = (obj: any) => {
    for (const key in obj) {
      if (obj[key] instanceof Date) {
        return key; // Return the first matching key
      }
    }
    return "created_at"; // Default return if no date keys are found
  };
  const getNumberKeysArray = (obj: Record<string, any>): string[] => {
    const numberKeys: string[] = [];

    for (const key in obj) {
      if (
        typeof obj[key] === "number" &&
        Object.prototype.toString.call(obj[key]) !== "[object Date]"
      ) {
        numberKeys.push(key); // Add keys with number values to the array
      }
    }

    return numberKeys.length > 0 ? numberKeys : ["count"]; // Return ["count"] if no number keys found
  };
  const newThread = () => {
    setIsNewThread(true);
    const generatedUuid = uuidv4();
    setThreadID(generatedUuid);
    setChatData([]);
    setChatView(true);
  };
  const scrollRef = useRef<HTMLDivElement>(null);
  // Effect to scroll to the bottom whenever data changes
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [chatData]);
  const threadScrollRef = useRef<HTMLDivElement>(null);
  // Effect to scroll to the top whenever data changes
  useEffect(() => {
    if (threadScrollRef.current) {
      threadScrollRef.current.scrollTop = 0; // Scroll to the top
    }
  }, [threads]);
  // Modal States
  // State to handle the modal open/close
  const [open, setOpen] = useState(false);
  // Functions to handle opening and closing of the modal
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // Reset Database Configuration
  const resetDatabaseConfiguration = (db_type: number) => {
    let dbHost = "";
    let dbDatabase = "";
    let dbUser = "";
    let dbPassword = "";
    let directConnection = false;

    if (db_type === clientDetailsInfo.db_type) {
      dbHost = clientDetailsInfo.db_host;
      dbDatabase = clientDetailsInfo.db_database;
      dbUser = clientDetailsInfo.db_user;
      dbPassword = clientDetailsInfo.db_password;
      directConnection = clientDetailsInfo.direct_connection ? true : false;
    }
    setDatabaseConfiguration({
      ...databaseConfiguration,
      db_type: db_type,
      db_host: dbHost,
      db_database: dbDatabase,
      db_user: dbUser,
      db_password: dbPassword,
      direct_connection: directConnection
    });
  };
  const validDatabaseData = () => {
    if (
      databaseConfiguration.db_host &&
      databaseConfiguration.db_database &&
      databaseConfiguration.db_type
    ) {
      if (
        databaseConfiguration.db_type === 2 &&
        databaseConfiguration.direct_connection
      ) {
        return true;
      }
      if (
        databaseConfiguration.db_type === 2 &&
        !databaseConfiguration.direct_connection &&
        databaseConfiguration.db_user &&
        databaseConfiguration.db_password
      ) {
        return true;
      }
      if (
        databaseConfiguration.db_type === 1 &&
        databaseConfiguration.db_user &&
        databaseConfiguration.db_password
      ) {
        return true;
      }
    }
    return false;
  };
  const establishDatabaseConnectionAndCreateClient = async () => {
    setDatabaseChecking(true);
    const clientData = await createClient(databaseConfiguration);
    if (clientData) {
      toast.success(
        `Connected to ${databaseConfiguration.db_host} database at ${databaseConfiguration.db_database}`
      );
      handleClose();
      clientDetailsFunc(databaseConfiguration.client_id);
    } else {
      toast.error("Connection not established");
    }
    setDatabaseChecking(false);
  };
  const clientDetailsFunc = async (client_id: string) => {
    const clientData: clientDetailsAPIResponse = await getClientDetails(
      client_id
    );
    if (clientData) {
      const clientDetailsValues: clientDetails = clientData.data;
      setClientDetailsInfo(clientDetailsValues);
      setDatabaseConfiguration({
        ...databaseConfiguration,
        db_type: clientDetailsValues.db_type,
        db_host: clientDetailsValues.db_host,
        db_database: clientDetailsValues.db_database,
        db_user: clientDetailsValues.db_user,
        db_password: clientDetailsValues.db_password,
        direct_connection: clientDetailsValues.direct_connection
      });
      getClientHistoryFunc();
      getPredefinedTemplatesFunction();
    }
  };
  return (
    <div style={{ height: "95vh", overflow: "hidden" }}>
      <Box
        sx={{ float: "left" }}
        width={"70%"}
        bgcolor={"white"}
        padding={5}
        height={"100vh"}
      >
        {!chatView && (
          <>
            <Typography variant="h5" gutterBottom className="mainTitle">
              Hello Victor
            </Typography>
            <Typography variant="h5" gutterBottom className="subTitle">
              How can I help you today?
            </Typography>
            <div style={{ clear: "both", marginBottom: 30, marginTop: 30 }}>
              <div style={{ position: "relative", width: "100%" }}>
                {/* <textarea
                  className="inputBox"
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  placeholder="Enter Prompt Here"
                />
                <Button className="inputSubmit" onClick={getResultsFromPrompt}>
                  Submit
                </Button> */}
                <div className="inputContainer">
                  <textarea
                    className="inputBox"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    placeholder="Enter Prompt Here"
                  />
                  <Button
                    className="inputSubmit"
                    onClick={getResultsFromPrompt}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ clear: "both" }}>
              <Box
                sx={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)" }}
              >
                {templatesLoading ? (
                  <CircularProgress key={"predefined_templates_loader"} />
                ) : (
                  <>
                    {templates.map((data: string, index: number) => (
                      <ListItem
                        key={index}
                        className="predefinedTemplates"
                        onClick={() => {
                          setPrompt(data);
                        }}
                      >
                        {data}
                      </ListItem>
                    ))}
                  </>
                )}
              </Box>
              <br />
            </div>
          </>
        )}
        {chatView && (
          <div style={{ height: "100vh", overflow: "hidden" }}>
            <div
              style={{ height: "70vh", background: "white", overflowY: "auto" }}
              ref={scrollRef}
            >
              {fetchingData && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh", // Full viewport height for parent
                    width: "100%" // Full viewport width for parent
                  }}
                >
                  <div
                    style={{
                      width: "300px", // Set desired width of child div
                      height: "200px", // Set desired height of child div
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center"
                    }}
                  >
                    <CircularProgress />
                  </div>
                </div>
              )}
              {!fetchingData &&
                chatData.map((object: any, index: number) => (
                  <div
                    style={{
                      clear: "both",
                      minHeight: "375px",
                      marginBottom: "100px"
                    }}
                    key={`result_${index}`}
                  >
                    <div
                      className="chatResultPromptDiv"
                      style={{ float: "right" }}
                    >
                      <h5 className="chatResultPromptDivText">
                        {object.prompt}
                      </h5>
                    </div>
                    <div
                      className="chatResutlViewDiv"
                      style={{
                        float: "left",
                        marginTop: 20,
                        height: "300px",
                        overflowY: "auto",
                        width: "80%",
                        borderRadius: "20px"
                      }}
                    >
                      {object.viewType === "TABLE_VIEW" && (
                        <TableContainer
                          className="chatResultTableBody"
                          component={Paper}
                          elevation={3}
                        >
                          <Table>
                            <TableHead>
                              <TableRow>
                                {object.result.labelHeader1.map(
                                  (tableRow: any, tableIndex: any) => (
                                    <TableCell
                                      key={`table_key_${tableIndex}`}
                                      style={{
                                        backgroundColor: "#1976d2",
                                        color: "#fff"
                                      }}
                                    >
                                      {tableRow}
                                    </TableCell>
                                  )
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {object.result.records.map(
                                (tableBodyRow: any, tableBodyIndex: any) => (
                                  <TableRow key={tableBodyIndex} hover>
                                    {Object.keys(tableBodyRow).map(
                                      (
                                        tableBodyRowKey,
                                        tableBodyRowColIndex
                                      ) => (
                                        <TableCell key={tableBodyRowColIndex}>
                                          {tableBodyRow[tableBodyRowKey]}
                                        </TableCell>
                                      )
                                    )}
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                      {object.viewType === "BAR_CHART" &&
                        object?.result?.barChartData &&
                        object.result.barChartData?.length > 0 && (
                          <BarChart
                            width={1000}
                            height={250}
                            data={object.result.barChartData}
                            margin={{
                              top: 50,
                              right: 30,
                              left: 20,
                              bottom: 5
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey={getFirstStringKey(
                                object.result.barChartData[0]
                              )}
                            />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar
                              key={index}
                              dataKey={getFirstNumberKey(
                                object.result.barChartData[0]
                              )}
                              fill={getRandomColorBarChart()}
                            />
                          </BarChart>
                        )}
                      {object.viewType === "PIE_CHART" &&
                        object?.result?.pieChartData &&
                        object.result.pieChartData?.length > 0 && (
                          <div style={{ marginLeft: "100px" }}>
                            <PieChart width={500} height={250}>
                              <Pie
                                data={object.result.pieChartData}
                                cx={200}
                                cy={150}
                                labelLine={false}
                                label={({ severity, percent }) =>
                                  `${severity}: ${(percent * 100).toFixed(0)}%`
                                }
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey={getFirstNumberKey(
                                  object.result.pieChartData[0]
                                )}
                              >
                                {object.result.pieChartData.map(
                                  (entry: any, index: number) => (
                                    <Cell
                                      key={`cell-${index}`}
                                      fill={COLORS[index % COLORS.length]}
                                    />
                                  )
                                )}
                              </Pie>
                            </PieChart>
                          </div>
                        )}
                      {object.viewType === "LINE_CHART" &&
                        object?.result?.lineChartData &&
                        object.result.lineChartData?.length > 0 && (
                          <LineChart
                            width={1000}
                            height={250}
                            data={object.result.lineChartData}
                            margin={{ top: 50, right: 30, left: 50, bottom: 5 }}
                          >
                            <XAxis
                              dataKey={getFirstDateKey(
                                object.result.lineChartData[0]
                              )}
                            />
                            <YAxis />
                            <Tooltip />
                            {getNumberKeysArray(
                              object.result.lineChartData[0]
                            ).map((key: any, index: number) => (
                              <Line
                                key={index}
                                type="monotone"
                                dataKey={key}
                                stroke={getRandomColor(index)}
                              />
                            ))}
                          </LineChart>
                        )}
                    </div>
                  </div>
                ))}
            </div>
            <div style={{ height: "20vh", background: "white" }}>
              <div style={{ clear: "both", marginBottom: 30, marginTop: 50 }}>
                <div style={{ position: "relative", width: "100%" }}>
                  <textarea
                    className="inputBoxChatView"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    placeholder="Enter Prompt Here"
                  />
                  <Button
                    className="inputSubmitChat"
                    onClick={getResultsFromPrompt}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Box>
      <Box
        sx={{ float: "left" }}
        width={"20%"}
        bgcolor={"white"}
        padding={5}
        height={"100vh"}
      >
        <div>
          <SettingsIcon
            style={{ float: "left", cursor: "pointer" }}
            onClick={() => {
              handleOpen();
            }}
          />
          <img
            style={{ float: "left", cursor: "pointer", marginLeft: "20px" }}
            src="create_thread.png"
            alt="newThreadImage"
            onClick={() => {
              newThread();
            }}
          />
          <HomeIcon
            style={{ float: "left", cursor: "pointer", marginLeft: "20px" }}
            onClick={() => {
              setChatView(false);
              setThreadID("");
            }}
          />
          <Typography variant="h5" gutterBottom className="sideBarThreadTitle">
            Last {Object.keys(threads).length} Threads
          </Typography>
        </div>
        {threads && (
          <div
            style={{
              clear: "both",
              marginTop: "40px",
              width: "100%",
              height: "75vh",
              overflowY: "auto"
            }}
            ref={threadScrollRef}
          >
            <Box sx={{ gridTemplateColumns: "repeat(1, 1fr)" }}>
              {threadLoading ? (
                <CircularProgress key={"thread_loading"} />
              ) : (
                <>
                  {Object.keys(threads).map(
                    (threadID: string, index: number) => (
                      <div
                        key={`thread_box_${index}`}
                        className="threadParentBox"
                        onClick={() => {
                          getChatData(threadID);
                        }}
                      >
                        <h5 className="threadBoxTitle">
                          {threads[threadID][0].prompt}
                        </h5>
                        <h5 className="threadDateTime">
                          {threads[threadID][0].date_time}
                        </h5>
                      </div>
                    )
                  )}
                </>
              )}
            </Box>
            <br />
          </div>
        )}
      </Box>
      {/* Setting Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography className="settingsLabel">Settings</Typography>
          <Grid container spacing={2} mt={2}>
            <Grid xs={4}>
              <Paper style={{ padding: 16, border: "none", boxShadow: "none" }}>
                <Typography
                  className={
                    activeMenu === 1
                      ? "sidebarMenuLabels labelBox"
                      : "sidebarMenuLabels"
                  }
                  onClick={() => {
                    setActiveMenu(1);
                  }}
                >
                  Connect Database
                </Typography>
                <Typography
                  onClick={() => {
                    setActiveMenu(2);
                  }}
                  className="sidebarMenuLabels"
                >
                  Help
                </Typography>
                <Typography
                  onClick={() => {
                    setActiveMenu(3);
                  }}
                  className="sidebarMenuLabels"
                >
                  Feedback
                </Typography>
              </Paper>
            </Grid>
            <Grid xs={1}></Grid>
            <Grid xs={7}>
              {activeMenu === 1 && (
                <Paper
                  style={{ padding: 16, border: "none", boxShadow: "none" }}
                >
                  <Typography className="sidebarMenuLabels">
                    Database Type
                  </Typography>
                  <Typography className="sidebarMenuLabels">
                    Please whitelist the "{PRODUCTION_IP}" address in your
                    MongoDB settings to grant our server access to your
                    database.
                  </Typography>
                  <div style={{ marginTop: "20px", display: "flex" }}>
                    <Typography
                      className={
                        activeDBType === 2
                          ? "sidebarMenuLabelsDatabase activeDBType"
                          : "sidebarMenuLabelsDatabase"
                      }
                      onClick={() => {
                        setActiveDBType(2);
                        resetDatabaseConfiguration(2);
                      }}
                    >
                      MongoDB
                    </Typography>
                    <Typography
                      className={
                        activeDBType === 1
                          ? "sidebarMenuLabelsDatabase activeDBType"
                          : "sidebarMenuLabelsDatabase"
                      }
                      onClick={() => {
                        setActiveDBType(1);
                        resetDatabaseConfiguration(1);
                      }}
                    >
                      MySQL
                    </Typography>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Typography className="sidebarMenuLabels">
                      Database Configuration
                    </Typography>
                    <Divider style={{ marginTop: "10px" }} />
                    <br />
                    {databaseChecking && (
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center"
                        }}
                      >
                        <CircularProgress key={"database_checking"} />
                      </div>
                    )}
                    {!databaseChecking && (
                      <>
                        {activeDBType === 2 && (
                          <FormControlLabel
                            control={
                              <GreenSwitch
                                checked={
                                  databaseConfiguration.direct_connection
                                }
                                onChange={(event: any) => {
                                  setDatabaseConfiguration({
                                    ...databaseConfiguration,
                                    direct_connection: event.target.checked
                                  });
                                }}
                              />
                            }
                            label="Direct Connection"
                          />
                        )}
                        <TextField
                          label="Database Host"
                          InputLabelProps={{ shrink: true }}
                          placeholder="Host Address"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          onChange={(event) => {
                            setDatabaseConfiguration({
                              ...databaseConfiguration,
                              db_host: event.target.value
                            });
                          }}
                          value={databaseConfiguration.db_host}
                        />
                        <TextField
                          label="Database Name"
                          InputLabelProps={{ shrink: true }}
                          placeholder="Database Name"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          onChange={(event) => {
                            setDatabaseConfiguration({
                              ...databaseConfiguration,
                              db_database: event.target.value
                            });
                          }}
                          value={databaseConfiguration.db_database}
                        />
                        {!databaseConfiguration.direct_connection && (
                          <>
                            <TextField
                              label="Database User"
                              InputLabelProps={{ shrink: true }}
                              placeholder="User Name"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              onChange={(event) => {
                                setDatabaseConfiguration({
                                  ...databaseConfiguration,
                                  db_user: event.target.value
                                });
                              }}
                              value={databaseConfiguration.db_user}
                            />
                            <TextField
                              label="Database Password"
                              InputLabelProps={{ shrink: true }}
                              placeholder="Password"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              type={showPassword ? "text" : "password"}
                              onChange={(event) => {
                                setDatabaseConfiguration({
                                  ...databaseConfiguration,
                                  db_password: event.target.value
                                });
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={handleTogglePasswordVisibility}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                              value={databaseConfiguration.db_password}
                            />
                          </>
                        )}
                        {activeDBType === 2 && (
                          <TextField
                            label="MongoDB Connection String"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={
                              databaseConfiguration.direct_connection
                                ? `mongodb://${databaseConfiguration.db_host}/${databaseConfiguration.db_database}?directConnection=true`
                                : `mongodb://${databaseConfiguration.db_user}:${databaseConfiguration.db_password}@${databaseConfiguration.db_host}/${databaseConfiguration.db_database}`
                            }
                            InputProps={{ readOnly: true }}
                          />
                        )}
                        <button
                          className="databaseConnectionButton"
                          onClick={() => {
                            establishDatabaseConnectionAndCreateClient();
                          }}
                          disabled={!validDatabaseData() ? true : false}
                        >
                          Establish
                        </button>
                      </>
                    )}
                  </div>
                </Paper>
              )}
              {activeMenu === 2 && (
                <Paper
                  style={{
                    padding: 16,
                    border: "none",
                    boxShadow: "none",
                    height: "400px",
                    overflowY: "scroll"
                  }}
                >
                  <Container maxWidth="md" style={{ marginTop: "2rem" }}>
                    <Typography variant="h4" gutterBottom>
                      Help Center
                    </Typography>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">
                          1. Establishing a Database Connection
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List>
                          <ListItem>
                            <ListItemText primary="Open the Application: Launch the application on your device." />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Navigate to Database Settings: Go to the settings section to input your database credentials." />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Enter Database Credentials: Fill in the required fields such as database type, hostname, username, password, and database name." />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Test the Connection: Click the 'Test Connection' button to ensure that your application can connect to the database." />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Save the Settings: Once the connection is established, make sure to save your settings to proceed." />
                          </ListItem>
                        </List>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">
                          2. Using Predefined Templates or Creating Your Own
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List>
                          <ListItem>
                            <ListItemText primary="Access Templates: Go to the 'Templates' section from the main menu." />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Select a Predefined Template: Choose from a list of available templates designed for common tasks." />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Create Your Own Template: Click on the 'Create Template' button, define the structure, and save it for future use." />
                          </ListItem>
                        </List>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">
                          3. Updating Database Connection Settings
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List>
                          <ListItem>
                            <ListItemText primary="Click on the Settings Icon: Locate the settings icon in the top right corner." />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Modify Connection Details: Update the fields with new database credentials." />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Test the New Connection: Click on 'Test Connection' to ensure that the new details are correct." />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Save Changes: Once confirmed, save your updated settings." />
                          </ListItem>
                        </List>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">
                          4. Creating New Threads
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List>
                          <ListItem>
                            <ListItemText primary="Click on the Edit Icon: Find the edit icon in the thread management section." />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Enter Thread Details: Fill in the necessary information such as thread title and description." />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Save the New Thread: Click 'Create' or 'Save' to add the new thread." />
                          </ListItem>
                        </List>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">
                          5. Viewing Thread History
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <List>
                          <ListItem>
                            <ListItemText primary="Access the Thread List: Navigate to the section where all your threads are displayed." />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Select a Thread: Click on the desired thread from the list." />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Review History: The history of the selected thread will be displayed." />
                          </ListItem>
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  </Container>
                </Paper>
              )}
              {activeMenu === 3 && (
                <Paper
                  style={{
                    padding: 16,
                    border: "none",
                    boxShadow: "none",
                    height: "400px",
                    overflowY: "scroll"
                  }}
                >
                  <Container maxWidth="sm" style={{ marginTop: "2rem" }}>
                    <Typography variant="h6" gutterBottom>
                      We value your feedback!
                    </Typography>
                    <Typography variant="body1">
                      If you have any comments or suggestions, please reach out
                      to us at:
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: "bold" }}>
                      contact@intucoreai-support.com
                    </Typography>
                  </Container>
                </Paper>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default DataInput;
